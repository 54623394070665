body {
  margin: 0;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  overflow: hidden;
}

h1 {
  font-weight: bold;
}
.flow-down-affects .list-group-item {
  border: none !important;
}
.flow-down-affects .list-group {
  --bs-list-group-bg: transparent;
}

.flow-down-affects .section-title {
  position: absolute;
  top: -22px;
  background: white;
  padding: 8px;
  border: 1px solid #f0efef;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
}

.section-title span {
  font-weight: 500;
}
.flow-down-affects section {
  border: 1px #f2ecec solid;
  border-radius: 5px;
  padding: 30px 10px 20px;
  background: linear-gradient(to right, #f7f7f7, #dbdbdb0a);
  position: relative;
}
.flow-down-affects .section-desc {
  font-size: 13px;
}

.flow-down-affects .department-licence {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0px;
}
.remove-func-confirmation + section.added-func-promotion {
  margin-top: 35px;
}
