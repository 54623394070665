
.modal.modal-enter-active,
.modal.modal-enter-done {
  display: block;
  opacity: 1;
}

.ReactModal__Overlay {
  z-index: 10;
}

.grid__small {
  max-width: 287px;
  width: 100%;
}

.spinner__wrapper {
  margin-top: 40vh;
  margin-left: calc(50vw - 200px);
}

.table__pager {
  display: flex;
  justify-content: flex-end;
  padding: 10px 16px;
}

.dropdown__input {
  width: 100%;
  max-width: 264px;
  padding: 18px 8px 18px;
  border: 1px solid #444b53;
  border-radius: 3px;

  .Dropdown-arrow {
    top: 24px;
  }
}

.custom__table {
  width: 100%;
  th:nth-child(1),
  th:nth-child(1) {
    width: 20px;
  }

  th:nth-child(3),
  td:nth-child(3) {
    display: none;
  }
}

.table {
  padding-bottom: 32px;
}

.label-text {
  font-family: Inter, sans-serif;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
  font-weight: 600;
}

.top-bar {
  padding: 14px 16px;
}

@media (max-width: 600px) {
  .spinner__wrapper {
    margin-left: 41vw;
  }
}
