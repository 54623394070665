.modal.modal-enter-active,
.modal.modal-enter-done {
  display: block;
  opacity: 1;
}

.modal.modal-enter-done .modal__overlay,
.modal.modal-enter-active .modal__overlay {
  backdrop-filter: blur(var(--modal-background-blur-radius)) opacity(1);
}

.modal.modal-enter-done .modal__content,
.modal.modal-enter-active .modal__content {
  opacity: 1;
  display: block;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.form__small .full-size-input .form-group {
  max-width: 100% !important;
  width: 100%;
}

.white-spinner {
  border-left-color: rgb(255 255 255 / 80%);
}

.department-usecase-list {
  position: relative;
}

.button-group .button {
  margin: 0.25rem 0.125rem;
}

.readonly input,
.readonly textarea,
.readonly select,
.readonly .control--checkbox {
  pointer-events: none;
  border-color: #babdc1;
  color: #a1a8ae;
  opacity: 1;
  -webkit-text-fill-color: #a1a8ae;
}

.full-width {
  display: block;
  height: 100vh;
}

.mini-width {
  width: 1em;
}

/* For disabled checkboxes to change its colour
Temporarily here until GBG component library fix it */
.control--disabled .control__checkbox {
  background-color: #BABDC1 !important;
}