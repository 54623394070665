.tri-container input[type='radio'] {
  display: none;
}

.licence-function-list .table__cell {
  position: relative;
}

.tri-container [type='radio']:checked+label {
  color: black;
}

.tri-container {
  display: inline-block;
  vertical-align: middle;
  width: 247px;
  height: 40px;
  border-radius: 80px;
  background-color: #6d6d86;
  position: absolute;
  top: 5px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
}

.tri-container label {
  border-radius: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.tri-container .switch {
  height: 34px;
  width: 85px;
  background-color: white;
  border-radius: 80px;
  position: absolute;
  left: 65px;
  top: 3px;
  transition: ease-in;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
  left: 3px;
}

.left-label {
  position: absolute;
}

.left-select+label {
  width: 84px;
}

@keyframes leftToCenter {
  from {
    left: 3px;
  }

  to {
    left: 74px;
  }
}

@keyframes leftToRight {
  from {
    left: 3px;
  }

  to {
    left: 159px;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 75px;
}

.center-label {
  position: absolute;
  left: 75px;
}

.center-select+label {
  height: 40px;
  width: 75px;
}

@keyframes centerToLeft {
  from {
    left: 75px;
  }

  to {
    left: 3px;
  }
}

@keyframes centerToRight {
  from {
    left: 75px;
  }

  to {
    right: 1px;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 159px;
}

.right-label {
  position: absolute;
  right: 2px;
}

.right-select+label {
  width: 88px;
}

@keyframes rightToLeft {
  from {
    left: 167px;
  }

  to {
    left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    left: 167px;
  }

  to {
    right: 75px;
  }
}