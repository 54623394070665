.table {
  .table__head {
    .table__cell--header {
      background-color: #eef0f2;
    }
  }

  .table__body {
    .table__row {
      .table__cell {
        background: #ffffff;

        &.table__radio {
          display: flex;

          label {
            padding-right: 32px;
          }

          .control:focus-within {
            box-shadow: none;
          }
        }

        &.table__avatar__group {
          display: flex;
          align-items: center;
          height: 89px;

          .table__avatar__group__name {
            display: flex;
            flex-direction: column;

            :nth-child(2) {
              color: #7e878f;
            }
          }
        }
      }

      &:hover {
        .table__cell {
          background: #f6f7f9;
        }
      }
    }
  }

  .table__cell {
    padding: 16px 32px 16px 16px;
  }
}

.clickable.table{
  .table__body{
    .table__row{
      cursor: pointer;
    }
  }
}