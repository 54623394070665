.dropzone {
  display: flex;
  justify-content: space-between;

  &__block {
    height: 728px;
    width: 45%;
  }

  &__info {
    display: flex;
    align-items: flex-start;

    &--details {
      display: flex;
      flex-direction: column;
    }

    .control {
      margin-top: 5px;
    }
  }

  &__controls {
    height: 728px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--block {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__description {
    color: #7e878f;
  }

  .card__body {
    position: relative;
    height: 76.4%;
    overflow-y: auto;
    overflow-x: hidden;

    .table__pager {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  .searchbar {
    margin-left: 16px;
  }
}
