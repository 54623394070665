.form__small {
  .form-group {
    max-width: 267px;
    width: 100%;
  }
}

.form__actions {
  display: flex;
  align-items: flex-end;
}

.form__switch {
  display: flex;
  align-items: center;

  label {
    margin-top: 0;
    margin-right: 8px;
  }
}